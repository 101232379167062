<template>
  <div class="container align-items-center">
    <div class="" v-if="ads.length > 0">
      <span style="font-size: 11px">Publicidad</span>
      <div v-for="ad in ads" :key="ad.id">
        <a
          :href="ad.redirection"
          target="_blank"
          rel="noopener"
          @click="setClickStats(ad.id)"
          class="btn"
        >
          <img :src="ad.image ? ad.image.route : ''" :alt="ad.name"
        /></a>
      </div>
    </div>
    <div v-else>
      <span style="font-size: 11px">Publicidad</span>
      <v-skeleton-loader
        :boilerplate="boilerplateActive"
        class="mx-auto"
        type="image"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['ads'],
    data() {
      return {
        show: true,
        boilerplateActive: true,
      }
    },
    watch: {
      ads(newValue, oldValue) {
        oldValue.forEach((ad) => {
          this.setTimeStats(ad.id)
        })
        newValue.forEach((ad) => {
          this.setPrintStats(ad.id)
        })
      },
    },
    methods: {
      setClickStats(id) {
        axios.patch(
          process.env.VUE_APP_API_DIRECTORY + 'advertisement-management/add-stats/' + id,
          {
            stats: [{ print_count: 0, print_time: 0, click_count: 1 }],
          }
        )
      },
      setTimeStats(id) {
        axios.patch(
          process.env.VUE_APP_API_DIRECTORY + 'advertisement-management/add-stats/' + id,
          {
            stats: [{ print_count: 0, print_time: 60, click_count: 0 }],
          }
        )
      },
      setPrintStats(id) {
        axios.patch(
          process.env.VUE_APP_API_DIRECTORY + 'advertisement-management/add-stats/' + id,
          {
            stats: [{ print_count: 1, print_time: 0, click_count: 0 }],
          }
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
  img {
    width: 100%;
    height: 100%;
  }
  .container {
    margin: 10px auto 50px auto;
    padding: 0px;
  }
</style>
